/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import {
	fetchScheduledEvents,
	createScheduledEvent,
	patchScheduledEvent,
	deleteScheduledEvent,
} from '../../services/bookings';
import { fetchUserCars, fetchCar } from '../../services/cars';
import { Link } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { toast } from 'react-toastify';
import Select from 'react-select';
import * as moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ModalSC from './modal';

class Schedule extends Component {
	constructor(props) {
		super(props);

		// this.onFileChange = this.onFileChange.bind(this);
		// this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			events: [],
			selectedEvent: null,
			loading: true,
			pages: -1,
		};

		//this.refreshData = _.debounce(this.refreshData.bind(this), 300);
	}

	componentDidMount() {
		this.refreshData();
	}

	refreshData(state, instance) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {};
		query['status'] = 'SCHEDULED';
		fetchScheduledEvents(`?${qs.stringify(query)}`).then((bookings) => {
			this.setState({ events: bookings, loading: false });
		});
	}

	formatEvents() {
		return this.state.events?.map((event) => {
			const { from, to, renter, travelPurpose } = event;
			return {
				title: `[${renter?.firstName} ${renter?.lastName}]`,
				start: from,
				end: to,
				allDay: false,
				description: `${travelPurpose}`,
				extendedProps: { ...event },
			};
		});
	}

	handleEventClick = ({ event }) => {
		if (window.confirm('Would you like to change this reservation?')) {
			this.setState({
				selectedEvent: event,
			});
			this.setModalShow(true);
		}
	};

	handleDateClick = (arg) => {
		if (window.confirm('Would you like to add a new reservation?')) {
			this.setState({
				selectedEvent: null,
			});
			this.setModalShow(true);
		}
	};

	setModalShow(show) {
		this.setState({ showModal: show });
	}

	render() {
		return (
			<div>
				<div className="content-wrapper">
					<section className="content">
						<div className="container-fluid">
							{this.state.showModal ? (
								<ModalSC
									show={this.state.showModal || true}
									onHide={() => this.setModalShow(false)}
									selectedEvent={this.state.selectedEvent}
								/>
							) : (
								<FullCalendar
									initialView="timeGridWeek"
									plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
									headerToolbar={{
										left: 'prev,next today',
										center: 'title',
										right: 'dayGridMonth,timeGridWeek,timeGridDay',
									}}
									editable={false}
									expandRows={true}
									dateClick={this.handleDateClick}
									eventClick={this.handleEventClick}
									eventTimeFormat={{
										hour: '2-digit',
										minute: '2-digit',
										meridiem: false,
									}}
									events={this.formatEvents()}
								/>
							)}
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default withRouter(Schedule);

import { formatDate, getBase64ImageFromURL } from '../../utils/utils';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export async function getPDFTravelReport(booking) {
	let images = [];
	if (booking?.returnImages?.length > 1) {
		for (const item of booking.returnImages) {
			images.push({
				image: await getBase64ImageFromURL(`${item}?w=100`),
				width: 100,
				margin: [15, 2]
			});
		}
	} else {
		if (booking.returnImage) {
			images.push({
				image: await getBase64ImageFromURL(
					`${booking.returnImage}?w=100`
				),
				width: 100,
				margin: [15, 2]
			});
		}
	}

	const PDF = {
		content: [
			booking?.ownerLogo && {
				image: await getBase64ImageFromURL(
					`${booking?.ownerLogo}?w=100`
				),
				width: 50
			},
			{
				text: `${booking?.ownerName || ''}`
			},
			{
				text: `${booking?.ownerAddress || ''}\n\n`,
				style: 'small'
			},
			{
				text: `Poročilo Najema ( ${booking.code} )\n\n`,
				style: 'header'
			},
			// BASIC DATA
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [
							`Vozilo : \n`,
							`Čas vožnje : \n`,
							`Cena najema : \n`,
							`Poraba goriva : \n`,
							`Razdalja : \n`,
							`Pot Od -> Do : \n`
						]
					},
					{
						text: [
							{
								text: `[ ${booking?.vehicle?.IMEI} ] ${booking?.vehicle?.type} ${booking?.vehicle?.model}\n`,
								style: 'grey'
							},
							{
								text: `${booking?.endTime || ''} min\n`,
								style: 'grey'
							},
							{
								text: `${booking?.endPrice || ''} €\n`,
								style: 'grey'
							},
							{
								text: `${
									booking?.vehicle?.fuelCapacity > 0
										? `${booking?.fuelDiff || ''} L\n`
										: `${booking?.fuelDiff || ''} %\n`
								}`,
								style: 'grey'
							},
							{
								text: `${
									booking?.mileageDone / 1000 || ''
								} km\n`,
								style: 'grey'
							},
							{
								text: `${
									booking?.vehicle?.liveInfo?.geoName || ''
								} -> ${booking?.returnGeoName || ''}\n`,
								style: 'grey'
							}
						]
					}
				]
			},
			// TABLE1
			{
				style: 'tableExample',
				layout: {
					fillColor: function (rowIndex, node, columnIndex) {
						return rowIndex % 2 === 0 ? '#ebe9ea' : null;
					}
				},
				table: {
					widths: ['*', '*', '*'],
					body: [
						[
							{
								text: 'Podatki',
								style: 'tableHeader'
							},
							{
								text: 'Začetek Najema',
								style: 'tableHeader'
							},
							{
								text: 'Konec Najema',
								style: 'tableHeader'
							}
						],
						[
							{
								text: 'Datum in Čas:',
								bold: true,
								italics: true
							},
							{
								text: booking?.from
									? formatDate(booking?.from)
									: '',
								alignment: 'center'
							},
							{
								text: booking?.to
									? formatDate(booking?.to)
									: '',
								alignment: 'center'
							}
						],
						[
							{
								text: 'Kilometri (km):',
								bold: true,
								italics: true
							},
							{
								text: `${
									booking?.vehicle?.liveInfo?.totalMileage /
										1000 || ''
								} km`,
								alignment: 'center'
							},
							{
								text: `${
									(booking?.vehicle?.liveInfo?.totalMileage +
										booking?.mileageDone) /
										1000 || ''
								} km`,
								alignment: 'center'
							}
						],
						[
							{
								text: 'Gorivo (L / %):',
								bold: true,
								italics: true
							},
							{
								text:
									booking?.vehicle?.fuelCapacity > 0
										? `${
												booking?.vehicle?.liveInfo
													?.fuelLevel || ''
										  } L`
										: `${
												booking?.vehicle?.liveInfo
													?.batteryPercentage || ''
										  } %`,
								alignment: 'center'
							},
							{
								text:
									booking?.vehicle?.fuelCapacity > 0
										? `${
												booking?.vehicle?.liveInfo
													?.fuelLevel +
													booking?.fuelDiff || ''
										  } L`
										: `${
												booking?.vehicle?.liveInfo
													?.batteryPercentage +
													booking?.fuelDiff || ''
										  } %`,
								alignment: 'center'
							}
						]
					]
				}
			}, // TABLE2
			{
				style: 'tableExample',
				layout: {
					fillColor: function (rowIndex, node, columnIndex) {
						return rowIndex % 2 === 0 ? '#ebe9ea' : null;
					}
				},
				table: {
					widths: ['*', '*'],
					body: [
						[
							{
								text: 'Lokacija Začetka',
								style: 'tableHeader'
							},
							{
								text: 'Lokacija Konca',
								style: 'tableHeader'
							}
						],
						[
							{
								text: `${
									booking?.vehicle?.liveInfo?.geoName || ''
								}`,
								bold: true,
								alignment: 'center'
							},
							{
								text: `${booking?.returnGeoName || ''}`,
								bold: true,
								alignment: 'center'
							}
						],
						[
							booking?.vehicle?.liveInfo?.locationLink
								? {
										text: 'Pokaži Na Zemljevidu',
										link: `${booking?.vehicle?.liveInfo?.locationLink}`,
										alignment: 'center',
										decoration: 'underline',
										color: 'blue'
								  }
								: '',
							booking?.returnLocation
								? {
										text: 'Pokaži Na Zemljevidu',
										link: `${booking?.returnLocation}`,
										alignment: 'center',
										decoration: 'underline',
										color: 'blue'
								  }
								: ''
						]
					]
				}
			}, // IMAGES
			{
				text: `Končne Slike\n`,
				style: 'subheader'
			},

			images && {
				columnGap: 10,
				columns: images
			}
		],
		defaultStyle: {
			fontSize: 12,
			lineHeight: 1.5
		},
		styles: {
			header: {
				fontSize: 20,
				bold: true,
				alignment: 'center'
			},
			subheader: {
				fontSize: 15,
				bold: true
			},
			quote: {
				italics: true
			},
			black: {
				fontSize: 12,
				bold: true
			},
			grey: {
				fontSize: 12,
				bold: true,
				color: 'grey'
			},
			small: {
				fontSize: 8
			},
			tableExample: {
				margin: [0, 15, 0, 15],
				lineHeight: 1.2
			},
			tableHeader: {
				alignment: 'center',
				bold: true,
				fontSize: 13,
				fillColor: '#a0979c',
				color: 'white'
			}
		}
	};

	pdfMake.createPdf(PDF).open();
}

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { fetchUserCars, fetchCarsCount } from '../../services/cars';
import { formatDate } from '../../utils/utils';
import ModalVC from './modal';
import 'react-table-v6/react-table.css';
import * as moment from 'moment';
import * as _ from 'lodash';

const currentUser = JSON.parse(localStorage.getItem('user'));

class CarsTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			columns: [],
			loading: true,
			pages: -1,
			carsOnline: 0,
			carsRented: 0,
			carsReserved: 0,
			carsOffline: 0,
			chosenVehicle: {},
			showModal: false
		};

		this.refreshData = this.refreshData.bind(this);
	}

	componentDidMount() {
		fetchCarsCount(currentUser.id).then(count => {
			this.setState({ pages: Math.ceil(count / 15) });
		});
		const showModalM =
			this.props?.history?.location?.state?.showModalM || false;
		const chosenVehicleM =
			this.props?.history?.location?.state?.chosenVehicleM || {};
		this.setState({ chosenVehicle: chosenVehicleM, showModal: showModalM });
	}

	getColumns() {
		return [
			{
				id: 'edit',
				Header: <i className="fas fa-external-link-square-alt fa-lg" />,
				accessor: d => d,
				Cell: ({ value }) => {
					return (
						<button
							className="btn btn-default"
							style={{ margin: 2 }}
						>
							<i className="fas fa-search-plus fa-lg" />
						</button>
					);
				},
				headerStyle: { textAlign: 'center' },
				style: { padding: 2, margin: 0 },
				maxWidth: 60,
				resizable: false,
				sortable: false,
				filterable: false
			},
			{
				Header: 'QR',
				resizable: false,
				maxWidth: 150,
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: 'IMEI'
			},
			{
				Header: 'NOTE',
				resizable: false,
				maxWidth: 150,
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: 'trackerInfo.btlockIMEI'
			},
			{
				id: 'warning',
				Header: <i className="fas fa-exclamation-circle fa-lg" />,
				headerStyle: { textAlign: 'center' },
				style: { textAlign: 'center' },
				accessor: d => d,
				Cell: ({ value }) => {
					if (value?.status === 'DELETED') {
						return <i className="fas fa-ban fa-lg" />;
					}
					if (
						moment().diff(value?.liveInfo?.modifiedAt, 'minutes') >
						5
					) {
						return (
							<div>
								<i className="fas fa-dizzy fa-lg" />
								SOS
							</div>
						);
					}
					if (value?.liveInfo?.batteryPercentage < 30) {
						return <i className="fas fa-battery-empty fa-lg" />;
					}
					if (
						value?.status === 'VERIFIED' &&
						value?.available === 'ONLINE'
					) {
						return <i className="fas fa-check-circle fa-lg" />;
					} else {
						return <i className="fas fa-eye-slash fa-lg" />;
					}
				},
				maxWidth: 60,
				resizable: false,
				filterable: false
			},
			{
				Header: 'Vehicle Type',
				style: { textAlign: 'center' },
				accessor: 'type',
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="SCOOTER">Scooter</option>
						<option value="BIKE">Bike</option>
						<option value="CAR">Car</option>
					</select>
				),
				maxWidth: 150,
				resizable: false,
				sortable: false
			},
			{
				id: 'appStatus',
				Header: 'Status on App',
				resizable: false,
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: d => d,
				Cell: ({ value }) => {
					if (
						value?.status === 'VERIFIED' &&
						value?.available === 'ONLINE'
					) {
						return <i className="fas fa-eye fa-lg" />;
					} else {
						return <i className="fas fa-eye-slash fa-lg" />;
					}
				},
				maxWidth: 150,
				filterable: false
			},
			{
				id: 'geoName',
				Header: 'City',
				resizable: false,
				style: { textAlign: 'center' },
				accessor: row =>
					row?.liveInfo?.geoName
						? row?.liveInfo?.geoName.toUpperCase()
						: 'UNKNOWN',
				filterable: false
			},
			{
				id: 'battery',
				Header: 'Battery %',
				resizable: false,
				filterable: false,
				sortable: false,
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: row => row?.liveInfo?.batteryPercentage + ' %',
				maxWidth: 100
			},

			{
				Header: 'State',
				accessor: 'status',
				style: { textAlign: 'center', fontWeight: 'bold' },
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="VERIFIED">Verified</option>
						<option value="HIDDEN">Hidden</option>
						<option value="SERVICE">Service</option>
						<option value="STOLEN">Stolen</option>
					</select>
				),
				maxWidth: 150,
				resizable: false,
				sortable: false
			},
			{
				id: 'Connected',
				Header: 'Last Connected',
				style: { textAlign: 'center' },
				accessor: d =>
					d?.liveInfo?.modifiedAt
						? formatDate(d.liveInfo.modifiedAt)
						: '/',
				maxWidth: 200,
				resizable: false,
				filterable: false
			},
			{
				Header: 'Available',
				resizable: false,
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: 'available',
				filterable: false,
				show: false
			}
		];
	}

	getRows() {
		return this.state.cars;
	}

	getTrProps = (state, rowInfo, instance) => {
		if (rowInfo) {
			if (rowInfo.row.available === 'RESERVED') {
				return {
					style: {
						background: 'rgba(104,204,202,0.5)'
					}
				};
			}
			if (rowInfo.row.available === 'DRIVING') {
				return {
					style: {
						background: 'rgba(244,78,59,0.5)'
					}
				};
			}
			if (rowInfo.row.status === 'DELETED') {
				return {
					style: {
						background: 'rgba(10,10,10,0.8)'
					}
				};
			}
			if (
				rowInfo.row.available === 'ONLINE' &&
				rowInfo.row.status === 'VERIFIED'
			) {
				return {
					style: {
						background: 'rgba(124,252,0,0.5)'
					}
				};
			}
			if (
				rowInfo.row.available === 'OFFLINE' ||
				rowInfo.row.status !== 'VERIFIED'
			) {
				return {
					style: {
						background: 'rgba(128,128,128,0.5)'
					}
				};
			}
			return {};
		}
		return {};
	};

	setModalShow(show) {
		if (show === false) {
			this.setState({ chosenVehicle: {} });
		}
		this.setState({ showModal: show });
	}

	handleClickOnEdit(rowInfo) {
		return {
			onClick: () => {
				this.setState({ chosenVehicle: rowInfo?.original });
				this.setModalShow(true);
			}
		};
	}

	refreshData(state, instance) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {
			pageSize: state.pageSize,
			page: state.page
		};
		if (state.sorted.length) {
			query['sortField'] = state.sorted[0].id;
			query['sortOrder'] = state.sorted[0].desc ? 'DESC' : 'ASC';
		}
		state.filtered.forEach(filter => {
			query[filter.id] = filter.value;
		});
		fetchUserCars(`?${qs.stringify(query)}`, currentUser.id).then(cars => {
			let cOnline = 0;
			let cRented = 0;
			let cOffline = 0;
			let cReserved = 0;

			for (let index = 0; index < cars.length; index++) {
				if (cars[index].available === 'ONLINE') {
					cOnline++;
				} else if (cars[index].available === 'DRIVING') {
					cRented++;
				} else if (cars[index].available === 'OFFLINE') {
					cOffline++;
				} else {
					cReserved++;
				}
			}

			this.setState({
				cars: cars,
				carsOnline: cOnline,
				carsRented: cRented,
				carsOffline: cOffline,
				carsReserved: cReserved,
				loading: false
			});
		});
	}

	render() {
		let columns = this.getColumns();
		let rows = this.getRows();
		return (
			<div>
				<div className="row">
					<div className="col-12 col-sm-6 col-md-3">
						<div className="info-box">
							<span className="info-box-icon bg-success elevation-1">
								<i className="fas fa-globe"></i>
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Online</span>
								<span className="info-box-number">
									{this.state.carsOnline}
								</span>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-md-3">
						<div className="info-box">
							<span className="info-box-icon bg-warning elevation-1">
								<i className="fas fa-coins"></i>
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Rented</span>
								<span className="info-box-number">
									{this.state.carsRented}
								</span>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-md-3">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<i className="fas fa-history"></i>
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Reserved</span>
								<span className="info-box-number">
									{this.state.carsReserved}
								</span>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-md-3">
						<div className="info-box">
							<span className="info-box-icon bg-danger elevation-1">
								<i className="fas fa-power-off"></i>
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Offline</span>
								<span className="info-box-number">
									{this.state.carsOffline}
								</span>
							</div>
						</div>
					</div>
				</div>
				<ReactTable
					className="-highlight"
					data={rows}
					columns={columns}
					getTrProps={this.getTrProps}
					getTdProps={(state, rowInfo, column, instance) => {
						if (!!column && column.id === 'edit') {
							return this.handleClickOnEdit(rowInfo);
						} else return {};
					}}
					pages={this.state.pages}
					loading={this.state.loading}
					manual={true} // informs React Table that you'll be handling sorting and pagination server-side
					onFetchData={this.refreshData}
					showPageSizeOptions={false}
					defaultPageSize={15}
					multiSort={false}
					filterable
					defaultSorted={[{ id: 'IMEI', desc: false }]}
				/>
				<ModalVC
					show={this.state.showModal}
					onHide={() => this.setModalShow(false)}
					vehicle={this.state.chosenVehicle}
				/>
			</div>
		);
	}
}

export default withRouter(CarsTable);

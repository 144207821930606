/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { fetchUser, sendGift } from '../../services/users';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import ListGroup from 'react-bootstrap/ListGroup';
import { formatDate, removeFromList } from '../../utils/utils';
import checkboxes from '../../utils/checkbox/countries';
import Checkbox from '../../utils/checkbox/checkbox';

const currentUser = JSON.parse(localStorage.getItem('user'));

export default class UserModal extends Component {
	constructor(props) {
		super(props);

		this.onFileChange = this.onFileChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			user: null,
			bookings: [],
			cars: [],
			giftAmount: '0',
		};

		this.userId = props?.userId;

		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	componentDidMount() {
		this.refreshUser();
	}

	refreshUser() {
		fetchUser(this.userId).then((user) => {
			this.setState({ user: user });
		});
	}

	handleChange(e) {
		this.setState({ giftAmount: e.target.value });
	}

	handleBlur(e) {
		var num = parseFloat(this.state.giftAmount);
		var cleanNum = num.toFixed(2);
		this.setState({ giftAmount: cleanNum });
	}

	onFileChange(e) {
		this.setState({ file: e.target.files[0] });
	}

	onSubmit(e, fileNum) {
		if (!this.state.file) {
			return;
		}
		e.preventDefault();
	}

	handleChangeGiftField() {
		if (
			window.confirm(
				`Warning! This action will give user ${parseFloat(this.state.giftAmount)} € !`
			)
		) {
			sendGift(this.userId, { amount: parseFloat(this.state.giftAmount) })
				.then((res) => {
					toast.success(`Gift send to promo balance`);
					this.refreshUser();
				})
				.catch((err) => {
					toast.error(err.response);
				});

			this.setState({ giftAmount: '0' });
		}
	}

	openImage(link) {
		if (!link) return;
		window.open(link, '_blank');
	}

	render() {
		let user = this.state.user;
		return (
			<div>
				{user && (
					<div className="card card-light card-outline card-tabs">
						<div className="card-header p-0 pt-1 border-bottom-0">
							<ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
								<li className="nav-item">
									<a
										className="nav-link active"
										id="custom-tabs-three-1-tab"
										data-toggle="pill"
										href="#custom-tabs-three-1"
										role="tab"
										aria-controls="custom-tabs-three-1"
										aria-selected="true"
									>
										Basic
									</a>
								</li>
								<li className="nav-item">
									<a
										className="nav-link"
										id="custom-tabs-three-2-tab"
										data-toggle="pill"
										href="#custom-tabs-three-2"
										role="tab"
										aria-controls="custom-tabs-three-2"
										aria-selected="false"
									>
										Advanced
									</a>
								</li>
							</ul>
							<div className="card-body">
								<div className="tab-content" id="custom-tabs-three-tabContent">
									<div
										className="tab-pane fade active show"
										id="custom-tabs-three-1"
										role="tabpanel"
										aria-labelledby="custom-tabs-three-1-tab"
									>
										<div className="row">
											<div className="col-12 col-sm-3">
												<div className="info-box bg-dark">
													<span className="info-box-icon">
														<i className="fas fa-portrait" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Avatar Photo
														</span>
														{user.avatarURL && (
															<a
																style={{
																	textDecoration: 'none',
																	color: 'white',
																}}
																target="_blank"
																rel="noopener noreferrer"
																href={user.avatarURL + '?w=300'}
															>
																Show Avatar
															</a>
														)}
													</div>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-lightblue">
													<span className="info-box-icon">
														<i className="fas fa-road" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Notification email
														</span>
														<span className="info-box-number">
															<a
																style={{
																	textDecoration: 'none',
																	color: 'white',
																}}
																href={
																	'mailto:' +
																	user.notificationEmail
																}
																target="_top"
																className="email-link"
															>
																{user.notificationEmail}
															</a>
														</span>
													</div>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-success">
													<span className="info-box-icon">
														<i className="fas fa-coins" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Total Balance
														</span>
														<span className="info-box-number">
															{`${user.balance.toFixed(2)} €` || ''}
														</span>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-success">
													<span className="info-box-icon">
														<i className="fas fa-percentage" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Promotional Balance
														</span>
														<span className="info-box-number">
															{`${user?.pBalance?.mbBalance.toFixed(
																2
															)} €` || ''}
														</span>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Full Name
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${user.firstName || '/'} ${
																	user.lastName || '/'
																}`}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Payment Method
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${
																	user.valuStatus ===
																	'ContractConfirmed'
																		? 'VALU'
																		: user.cardMask
																		? 'CARD' +
																		  user.cardMask.substr(
																				user.cardMask
																					.length - 5
																		  )
																		: 'NONE'
																}`}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Allowed Promo Content
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${
																	user.allowPromoMB ? 'YES' : 'NO'
																}`}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																AutoTopUp Status
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${user.autoTopUp ? 'YES' : 'NO'}`}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>

										<hr />

										<div className="row">
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																App Language
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${
																	user?.settingsMB?.language ||
																	'/'
																}`}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Invite Code
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${user?.inviteCode || '/'}`}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																GoGiro Created Date
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${
																	formatDate(
																		user?.infoGDPR
																			?.joinedMobility
																	) || '/'
																}`}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Created Date
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${
																	formatDate(user?.createdAt) ||
																	'/'
																}`}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="tab-pane fade"
										id="custom-tabs-three-2"
										role="tabpanel"
										aria-labelledby="custom-tabs-three-2-tab"
									>
										<div className="row">
											<div className="col-md-8">
												<div className="card card-info collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-exchange-alt fa-lg"
																style={{
																	marginRight: '10px',
																}}
															/>
															<strong>{` Exchange `}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="col-12 col-sm-8">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<label htmlFor="inputName">
																					Gift Promotional
																					Balance
																				</label>
																				<div className="input-group">
																					<div className="input-group-prepend">
																						<span className="input-group-text">
																							<i className="fas fa-coins" />
																						</span>
																					</div>

																					<input
																						type="number"
																						step={'.01'}
																						precision={
																							2
																						}
																						min={0}
																						value={
																							this
																								.state
																								.giftAmount
																						}
																						onChange={
																							this
																								.handleChange
																						}
																						onBlur={
																							this
																								.handleBlur
																						}
																					/>
																					<button
																						onClick={() =>
																							this.handleChangeGiftField()
																						}
																						className="btn btn-primary save-btn"
																						type="button"
																					>
																						Give{' '}
																						<i className="fas fa-hand-holding-usd" />
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="col-md-4">
												<div className="card card-danger collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-exclamation-triangle fa-lg"
																style={{
																	marginRight: '10px',
																}}
															/>
															<strong>{` Commands `}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="info-box">
																	<div className="info-box-content"></div>
																	<span className="info-box-icon bg-warning elevation-1">
																		<i className="fas fa-user-check" />
																	</span>
																</div>
															</div>
															<div className="row">
																<div className="info-box">
																	<div className="info-box-content"></div>
																	<span className="info-box-icon bg-warning elevation-1">
																		<i className="fas fa-user-slash" />
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

import axios from 'axios';
import { getAuthHeaders } from './utils';

let API_URL = process.env.REACT_APP_API_HOST;

export function fetchPromosCount() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/reports/count`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchPromos() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/reports`, getAuthHeaders()).then(res => {
			resolve(res.data);
		});
	});
}

export function fetchPromo(promoId) {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_URL}/reports/${promoId}`, getAuthHeaders())
			.then(res => {
				resolve(res.data);
			});
	});
}

export function updatePromo(promoId, payload) {
	return new Promise((resolve, reject) => {
		axios
			.patch(`${API_URL}/reports/${promoId}`, payload, getAuthHeaders())
			.then(res => {
				resolve(res.data);
			});
	});
}

export function resolveReport(promoId, payload) {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${API_URL}/reports/${promoId}/resolve`,
				payload,
				getAuthHeaders()
			)
			.then(res => {
				resolve(res.data);
			});
	});
}

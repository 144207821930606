const checkboxes = [
	{ name: 'Slovenia', value: 'slo8edf9-4c33-4ac7-b95a-aa782f876ded' },
	{ name: 'Austria', value: 'aut8edf9-4c33-4ac7-b95a-aa782f876ded' },
	{ name: 'Italy', value: 'ita8edf9-4c33-4ac7-b95a-aa782f876ded' },
	{ name: 'Hungary', value: 'aab6e5e9-4cc6-4f39-9613-f16652f2dbfa' },
	{ name: 'Bosnia and Herzegovina', value: '5509116b-f8f8-459c-bdfd-c4f1d7a50b71' },
	{ name: 'Montenegro', value: '2362fdcf-5c19-4876-a38b-c04a179e8aef' },
	{ name: 'Republic of Serbia', value: 'afcb6048-5014-4a8c-a703-f3d30b081cf7' },
	{ name: 'Germany', value: '9e8eb97b-3ffc-4dc7-a3f9-a4f919913f61' },
	{ name: 'Croatia', value: 'd523b658-6553-4187-a3ac-891b55e943d2' },
];

export default checkboxes;

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import qs from 'qs';
import { fetchCars } from '../../services/cars';
import Checkbox from '../../utils/checkbox/checkbox';

import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import './map.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { ReactComponent as ScooterIcon } from '../../assets/defscooter.svg';
import { ReactComponent as BikeIcon } from '../../assets/defbike.svg';
import { ReactComponent as CarIcon } from '../../assets/defcar.svg';

mapboxgl.workerClass = MapboxWorker;

class Map extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userLocation: { latitude: 46.05108, longitude: 14.50513 },
			viewport: {
				height: '100vh',
				width: '100vw',
				latitude: 46.05108,
				longitude: 14.50513,
				zoom: 15
			},
			vehicles: [],
			selectedVehicle: null,
			checkLowBattery: false,
			checkMidBattery: false,
			mapStyle: 'light-v10'
		};

		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
	}

	componentDidMount() {
		navigator.geolocation.getCurrentPosition(
			position => {
				let newViewport = {
					height: '100vh',
					width: '100vw',
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
					zoom: 15
				};
				let newUserLocation = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				};
				this.setState({
					viewport: newViewport,
					userLocation: newUserLocation
				});
			},
			error => {
				console.log(error);
			}
		);
		this.fetchCarsAPI();
	}

	fetchCarsAPI = () => {
		let query = { pageSize: 250 };
		fetchCars(`?${qs.stringify(query)}`).then(cars => {
			cars = cars.filter(el => el.status !== 'STOLEN'); // remove stolen
			this.setState({
				vehicles: cars
			});
		});
	};

	loadCarMarkers = () => {
		return this.state.vehicles.map(vehicle => {
			return (
				<Marker
					key={vehicle.id}
					latitude={parseFloat(vehicle?.liveInfo?.lat)}
					longitude={parseFloat(vehicle?.liveInfo?.lon)}
					offsetLeft={-30}
					offsetTop={-80}
				>
					{vehicle?.type === 'CAR' &&
						(vehicle?.liveInfo?.batteryPercentage >= 60 ? (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<CarIcon
										fill="#009a00"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						) : vehicle?.liveInfo?.batteryPercentage < 60 &&
						  vehicle?.liveInfo?.batteryPercentage >= 25 ? (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<CarIcon
										fill="#ffb700"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						) : (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<CarIcon
										fill="#e60000"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						))}
					{vehicle?.type === 'BIKE' &&
						(vehicle?.liveInfo?.batteryPercentage >= 60 ? (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<BikeIcon
										fill="#009a00"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						) : vehicle?.liveInfo?.batteryPercentage < 60 &&
						  vehicle?.liveInfo?.batteryPercentage >= 25 ? (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<BikeIcon
										fill="#ffb700"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						) : (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<BikeIcon
										fill="#e60000"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						))}
					{vehicle?.type === 'SCOOTER' &&
						(vehicle?.liveInfo?.batteryPercentage >= 60 ? (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<ScooterIcon
										fill="#009a00"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						) : vehicle?.liveInfo?.batteryPercentage < 60 &&
						  vehicle?.liveInfo?.batteryPercentage >= 25 ? (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<ScooterIcon
										fill="#ffb700"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						) : (
							<div style={{ fontWeight: 'bold' }}>
								<div className="container-fluid">
									{`[${vehicle?.liveInfo?.batteryPercentage}%]`}
								</div>
								<div className="container-fluid">
									<ScooterIcon
										fill="#e60000"
										onClick={() => {
											this.setSelectedVehicle(vehicle);
										}}
									/>
								</div>
							</div>
						))}
				</Marker>
			);
		});
	};

	handleCheckboxChange(e) {
		const item = e.target.name;
		const isChecked = e.target.checked;

		if (item === 'midbattery') {
			this.setState(() => ({
				checkMidBattery: isChecked,
				checkLowBattery: false
			}));
		}
		if (item === 'lowbattery') {
			this.setState(() => ({
				checkMidBattery: false,
				checkLowBattery: isChecked
			}));
		}
		if (isChecked) {
			let filteredVehicles = this.state.vehicles;
			if (item === 'midbattery') {
				filteredVehicles = filteredVehicles.filter(
					car =>
						car.liveInfo.batteryPercentage > 25 &&
						car.liveInfo.batteryPercentage < 40
				);
			}
			if (item === 'lowbattery') {
				filteredVehicles = filteredVehicles.filter(
					car => car.liveInfo.batteryPercentage <= 25
				);
			}

			this.setState({ vehicles: filteredVehicles });
		} else {
			this.fetchCarsAPI();
		}
	}

	closePopup = () => {
		this.setState({
			selectedVehicle: null
		});
	};

	setSelectedVehicle = object => {
		this.setState({
			selectedVehicle: object
		});
	};

	goToCar = vehicleId => {
		this.props.history.push('/pnl/vehicles/' + vehicleId);
	};

	switchMapStyle = () => {
		if (this.state.mapStyle === 'light-v10') {
			this.setState({
				mapStyle: 'dark-v10'
			});
		} else if (this.state.mapStyle === 'dark-v10') {
			this.setState({
				mapStyle: 'streets-v11'
			});
		} else if (this.state.mapStyle === 'streets-v11') {
			this.setState({
				mapStyle: 'satellite-v9'
			});
		} else {
			this.setState({
				mapStyle: 'light-v10'
			});
		}
	};

	render() {
		return (
			<div>
				<div className="content-wrapper">
					<section className="content">
						<div className="container-fluid">
							<ReactMapGL
								{...this.state.viewport}
								mapboxApiAccessToken={
									process.env.REACT_APP_MAP_TOKEN
								}
								mapStyle={
									'mapbox://styles/mapbox/' +
									this.state.mapStyle
								}
								onViewportChange={viewport =>
									this.setState({ viewport })
								}
							>
								<div className="overlayStyle">
									<div className="container-fluid sidebarStyle">
										<i
											className="fas fa-square"
											style={{ color: '#009a00' }}
										/>
										{` > 60% > `}
										<i
											className="fas fa-square"
											style={{ color: '#ffb700' }}
										/>
										{` > 25% > `}
										<i
											className="fas fa-square"
											style={{ color: '#e60000' }}
										/>
									</div>
									<div className="container-fluid sidebarStyle">
										{!this.state.checkMidBattery ? (
											<div className="form-check">
												<label key={'lowbattery'}>
													<Checkbox
														name={'lowbattery'}
														checked={
															this.state
																.checkLowBattery
														}
														onChange={
															this
																.handleCheckboxChange
														}
													/>
													{'Only < 25% Battery'}
												</label>
											</div>
										) : null}
										{!this.state.checkLowBattery ? (
											<div className="form-check">
												<label key={'midbattery'}>
													<Checkbox
														name={'midbattery'}
														checked={
															this.state
																.checkMidBattery
														}
														onChange={
															this
																.handleCheckboxChange
														}
													/>
													{'Only 25 - 40% Battery'}
												</label>
											</div>
										) : null}
									</div>
									<div className="info-box-content">
										<button
											className="btn btn-block btn-secondary btn-sm"
											onClick={() =>
												this.switchMapStyle()
											}
										>
											Switch Map Style
										</button>
									</div>
								</div>

								<Marker
									latitude={parseFloat(
										this.state.userLocation.latitude
									)}
									longitude={parseFloat(
										this.state.userLocation.longitude
									)}
								>
									<i
										className="fas fa-street-view fa-lg"
										style={{ color: 'blue' }}
									></i>
								</Marker>
								{this.loadCarMarkers()}
								{this.state.selectedVehicle !== null ? (
									<Popup
										latitude={parseFloat(
											this.state.selectedVehicle.liveInfo
												.lat
										)}
										longitude={parseFloat(
											this.state.selectedVehicle.liveInfo
												.lon
										)}
										closeOnClick={false}
										onClose={this.closePopup}
									>
										<div className="container-fluid">
											<Link
												className="view-vehicle"
												to={{
													pathname: '/pnl/vehicles/',
													state: {
														showModalM: true,
														chosenVehicleM:
															this.state
																?.selectedVehicle
													}
												}}
											>
												<div className="back-btn">
													<i className="fas fa-search" />
													View
												</div>
											</Link>
											<span>
												{
													this.state?.selectedVehicle
														?.IMEI
												}
												{this.state?.selectedVehicle
													?.trackerInfo?.btlockIMEI
													? ` | ${this.state?.selectedVehicle?.trackerInfo?.btlockIMEI}`
													: ''}
											</span>
										</div>
									</Popup>
								) : null}
							</ReactMapGL>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default withRouter(Map);

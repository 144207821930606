import jwtDecode from 'jwt-decode';

export function getDecodedJwt() {
	let accessToken = localStorage.getItem('accessToken');
	if (accessToken) {
		try {
			return jwtDecode(accessToken);
		} catch (err) {
			console.error(err);
		}
	}
}

export function isJwtTokenValid() {
	let decodedJwt = getDecodedJwt();
	if (decodedJwt) {
		return decodedJwt.exp > Date.now();
	}
	return false;
}

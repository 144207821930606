/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
	patchUserCar,
	transportVehicleOFF,
	transportVehicleON,
	fetchCar,
	serviceCar,
	releaseBTLock,
	beepForVehicle,
	lookForVehicle,
	lastRentVehicle,
	resetBTLock,
	resetIoT,
	carDoorUnlock,
	carDoorLock,
	carEngineEnable,
	carEngineDisable,
	geofenceCountryAdd,
	geofenceCountryRemove
} from '../../services/cars';
import { toast } from 'react-toastify';
import ListGroup from 'react-bootstrap/ListGroup';
import { formatDate, removeFromList } from '../../utils/utils';
import checkboxes from '../../utils/checkbox/countries';
import Checkbox from '../../utils/checkbox/checkbox';

const currentUser = JSON.parse(localStorage.getItem('user'));

export default class CarModal extends Component {
	constructor(props) {
		super(props);

		this.onFileChange = this.onFileChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			checkedItems: new Map(),
			car: {
				IMEI: '',
				type: '',
				status: '',
				user: {
					email: ''
				},
				serialNumber: '',
				trackerInfo: {},
				liveInfo: {},
				pricing: {},
				lastState: {},
				private: {},
				geofence: {}
			},
			file: '',
			locationLink: '',
			phoneInput: '',
			isLookForDisabled: false,
			isBTLockBtnDisabled: false,
			isBTLockRstBtnDisabled: false,
			isIoTRstBtnDisabled: false,
			lastRent: null,
			noteInput: null
		};

		this.carId = props?.carId;
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.handleNoteChange = this.handleNoteChange.bind(this);
	}

	componentDidMount() {
		this.refreshCar();
	}

	refreshCar() {
		fetchCar(this.carId).then(car => {
			let carData = { ...car };
			this.setState({ car: carData });
			this.setState({ noteInput: car.trackerInfo.btlockIMEI });
			this.setState({
				locationLink: `http://www.google.com/maps/place/${carData?.liveInfo?.lat},${carData?.liveInfo?.lon}`
			});
			carData.geofence.allowedCountries.map(item =>
				this.setState(prevState => ({
					checkedItems: prevState.checkedItems.set(item, true)
				}))
			);
			lastRentVehicle(this.carId)
				.then(rents => {
					this.setState({ lastRent: rents[0] });
				})
				.catch(err => {});
		});
	}

	handleNoteChange(e) {
		this.setState({ noteInput: e.target.value });
	}

	handleChangeCarStatus(carStatus) {
		patchUserCar(this.carId, this.state.car.userId, { status: carStatus })
			.then(res => {
				this.refreshCar();
				toast.success(`Vehicle status changed to ` + carStatus);
			})
			.catch(err => {
				toast.error(err.response);
			});
	}

	handleChangeTankField(e, field) {
		let capacity = this.state.car.fuelCapacity;
		capacity = e.target.value;
		let carData = this.state.car;
		carData.fuelCapacity = capacity;
		this.setState({ car: carData });
	}

	handleServiceCar(direction) {
		if (window.confirm(`This action will put vehicle in SERVICE status!`)) {
			serviceCar(this.carId, { direction: direction })
				.then(res => {
					this.refreshCar();
					toast.success(`Vehicle being serviced ` + direction);
				})
				.catch(err => {
					toast.error(err.response);
				});
		}
	}

	handleChangeCarTank() {
		patchUserCar(this.carId, this.state.car.userId, {
			fuelCapacity: Number(this.state.car.fuelCapacity)
		})
			.then(res => {
				this.refreshCar();
				toast.success(`Vehicle tank capacity changed`);
			})
			.catch(err => {
				toast.error(err.response);
			});
	}

	handleLockReleaseCar() {
		if (window.confirm(`This action will unlock BT lock!`)) {
			this.setState({
				isBTLockBtnDisabled: true
			});

			releaseBTLock(this.carId)
				.then(res => {
					this.refreshCar();
					toast.success(`Vehicle BT-Lock will unlock!`);
				})
				.catch(err => {
					toast.error(err.response);
				});

			setTimeout(
				() => this.setState({ isBTLockBtnDisabled: false }),
				5000
			);
		}
	}

	handleLockRestartCar() {
		if (window.confirm('This action will restart IoT & unlock BT-Lock!')) {
			this.setState({
				isBTLockRstBtnDisabled: true
			});
			resetBTLock(this.carId)
				.then(res => {
					this.refreshCar();
					toast.success(`Vehicle BTLock restarted`);
				})
				.catch(err => {
					toast.error(err.response);
				});

			setTimeout(
				() => this.setState({ isBTLockRstBtnDisabled: false }),
				10000
			);
		}
	}

	handleIoTRestartCar() {
		if (window.confirm('This action will restart IoT!')) {
			this.setState({
				isIoTRstBtnDisabled: true
			});
			resetIoT(this.carId)
				.then(res => {
					this.refreshCar();
					toast.success(`Vehicle IoT restarted`);
				})
				.catch(err => {
					toast.error(err.response);
				});

			setTimeout(
				() => this.setState({ isIoTRstBtnDisabled: false }),
				10000
			);
		}
	}

	handleLookForCar() {
		if (window.confirm('This action will flash LED & beep alarm!')) {
			this.setState({
				isLookForDisabled: true
			});
			beepForVehicle(this.carId)
				.then(res => {
					lookForVehicle(this.carId)
						.then(res => {
							this.refreshCar();
							toast.success(`Vehicle flashing & beeping`);
						})
						.catch(err => {
							toast.error(err.response);
						});
				})
				.catch(err => {
					toast.error(err.response);
				});

			setTimeout(() => this.setState({ isLookForDisabled: false }), 5000);
		}
	}

	handleTransportCar(action) {
		if (action) {
			if (window.confirm('This action will unlock vehicle & BT lock!')) {
				releaseBTLock(this.carId)
					.then(res => {
						transportVehicleON(this.carId)
							.then(res => {
								this.refreshCar();
								toast.success(`Vehicle unlocked & turned on`);
							})
							.catch(err => {
								toast.error(err.response);
							});
					})
					.catch(err => {
						toast.error(err.response);
					});
				this.handleChangeCarStatus('HIDDEN');
			}
		} else {
			if (
				window.confirm(
					'This action will lock vehicle & unlock BT-Lock!\n You need to enable it on APP!'
				)
			) {
				transportVehicleOFF(this.carId)
					.then(res => {
						this.refreshCar();
						toast.success(
							`Press Show On APP button to see vehicle in APP!`
						);
					})
					.catch(err => {
						toast.error(err.response);
					});
			}
		}
	}

	handleDoorLockCar(action) {
		if (action) {
			if (window.confirm('This action will unlock vehicle doors!')) {
				carDoorUnlock(this.carId)
					.then(res => {
						this.refreshCar();
						toast.success(`Vehicle unlocked`);
					})
					.catch(err => {
						toast.error(err.response);
					});
			}
		} else {
			if (window.confirm('This action will lock vehicle doors!')) {
				carDoorLock(this.carId)
					.then(res => {
						this.refreshCar();
						toast.success(`Vehicle locked`);
					})
					.catch(err => {
						toast.error(err.response);
					});
			}
		}
	}

	handleEngineKillCar(action) {
		if (action) {
			if (window.confirm('This action will disable vehicle engine!')) {
				carEngineDisable(this.carId)
					.then(res => {
						this.refreshCar();
						toast.success(`Vehicle engine Disabled`);
					})
					.catch(err => {
						toast.error(err.response);
					});
			}
		} else {
			if (window.confirm('This action will enable vehicle engine!')) {
				carEngineEnable(this.carId)
					.then(res => {
						this.refreshCar();
						toast.success(`Vehicle engine Enabled`);
					})
					.catch(err => {
						toast.error(err.response);
					});
			}
		}
	}

	handlePhoneSaveClick(command, el) {
		switch (command) {
			case 'DELETE':
				if (this.state.car?.private?.phoneList.indexOf(el) !== -1) {
					const newPhoneList = removeFromList(
						this.state.car?.private?.phoneList,
						el
					);
					let payload = { private: { phoneList: newPhoneList } };
					patchUserCar(this.carId, this.state.car.userId, payload)
						.then(updatedCar => {
							this.setState({ car: updatedCar });
							toast.success(`Phone ${el} removed`);
							this.setState({ phoneInput: '' });
						})
						.catch(err => {
							toast.error(err.response);
						});
				}
				break;

			default:
				if (
					this.state?.phoneInput.trim() !== '' &&
					this.state?.phoneInput.length > 6
				) {
					const newPhoneNum = `+${this.state?.phoneInput
						.trim()
						.replace(/\D/g, '')}`;
					if (
						this.state.car.private.phoneList.indexOf(
							newPhoneNum
						) !== -1
					) {
						toast.error(`Phone ${newPhoneNum} already exists`);
						return;
					}
					this.state.car.private.phoneList.push(newPhoneNum);
					let payload = {
						private: {
							phoneList: this.state?.car?.private?.phoneList
						}
					};
					patchUserCar(this.carId, this.state.car.userId, payload)
						.then(updatedCar => {
							this.setState({ car: updatedCar });
							toast.success(
								`Phone ${newPhoneNum} added to allowed list`
							);
							this.setState({ phoneInput: '' });
						})
						.catch(err => {
							toast.error(err.response);
						});
				}
				break;
		}
	}

	handleChangePhoneField(e, field) {
		let phone = this.state.phoneInput.trim().replace(/\D/g, '');
		phone = e.target.value;
		this.setState({ phoneInput: phone });
	}

	onFileChange(e) {
		this.setState({ file: e.target.files[0] });
	}

	onSubmit(e, fileNum) {
		if (!this.state.file) {
			return;
		}
		e.preventDefault();
	}

	handleCheckboxChange(e) {
		const item = e.target.name;
		const isChecked = e.target.checked;
		if (isChecked) {
			geofenceCountryAdd(this.carId, item)
				.then(updatedCar => {
					this.setState({ car: updatedCar });
				})
				.catch(err => {
					toast.error(err.response);
				});
		} else {
			geofenceCountryRemove(this.carId, item)
				.then(updatedCar => {
					this.setState({ car: updatedCar });
				})
				.catch(err => {
					toast.error(err.response);
				});
		}
		this.setState(prevState => ({
			checkedItems: prevState.checkedItems.set(item, isChecked)
		}));
	}

	handleVehicleNoteSubmit() {
		if (
			window.confirm(
				`Warning! This action will change note of the vehicle`
			)
		) {
			patchUserCar(this.state.car.id, this.state.car.userId, {
				trackerInfo: {
					trackerIMEI: this.state.car.trackerInfo.trackerIMEI,
					trackerPhone: this.state.car.trackerInfo.trackerPhone,
					btlockIMEI: this.state.noteInput
				}
			})
				.then(res => {
					toast.success(`Note Changed`);
					this.refreshCar();
				})
				.catch(err => {
					toast.error(err.response);
				});
		}
	}

	render() {
		const location1 = {
			color: 'green',
			textDecoration: 'underline',
			fontWeight: 'bolder'
		};

		const location2 = {
			textDecoration: 'underline',
			fontWeight: 'bolder',
			color: 'purple'
		};

		const lastPhoto = {
			textDecoration: 'underline',
			fontWeight: 'bolder',
			color: '#dd9613'
		};
		let car = this.state.car;
		return (
			<div>
				{car && (
					<div className="card card-light card-outline card-tabs">
						<div className="card-header p-0 pt-1 border-bottom-0">
							<ul
								className="nav nav-tabs"
								id="custom-tabs-three-tab"
								role="tablist"
							>
								<li className="nav-item">
									<a
										className="nav-link active"
										id="custom-tabs-three-1-tab"
										data-toggle="pill"
										href="#custom-tabs-three-1"
										role="tab"
										aria-controls="custom-tabs-three-1"
										aria-selected="true"
									>
										Basic
									</a>
								</li>
								<li className="nav-item">
									<a
										className="nav-link"
										id="custom-tabs-three-2-tab"
										data-toggle="pill"
										href="#custom-tabs-three-2"
										role="tab"
										aria-controls="custom-tabs-three-2"
										aria-selected="false"
									>
										Advanced
									</a>
								</li>
							</ul>
							<div className="card-body">
								<div
									className="tab-content"
									id="custom-tabs-three-tabContent"
								>
									<div
										className="tab-pane fade active show"
										id="custom-tabs-three-1"
										role="tabpanel"
										aria-labelledby="custom-tabs-three-1-tab"
									>
										<div className="row">
											<div className="col-12 col-sm-3">
												<div className="info-box bg-success">
													<span className="info-box-icon">
														{car?.fuelCapacity <
														0 ? (
															<i className="fas fa-charging-station" />
														) : (
															<i className="fas fa-gas-pump" />
														)}
													</span>
													<div className="info-box-content">
														{car?.fuelCapacity <
														0 ? (
															<span className="info-box-text">
																Battery
															</span>
														) : (
															<span className="info-box-text">
																Fuel
															</span>
														)}

														{car?.fuelCapacity <
														0 ? (
															<span className="info-box-number">
																{`${car?.liveInfo?.batteryPercentage} `}
																<small>%</small>
															</span>
														) : (
															<span className="info-box-number">
																{`${(
																	car
																		?.liveInfo
																		?.fuelLevel *
																	100
																).toFixed(2)}`}
																<small>%</small>
															</span>
														)}
													</div>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-dark">
													<span className="info-box-icon">
														<i className="fas fa-road" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Mileage
														</span>
														<span className="info-box-number">
															{`${
																car?.liveInfo
																	?.totalMileage /
																1000
															} km`}
														</span>
													</div>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-lightblue">
													<span className="info-box-icon">
														<i className="fas fa-stopwatch" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Price Per Minute
														</span>
														<span className="info-box-number">
															{`${
																car?.pricing
																	?.pricePerMinute
																	? car?.pricing?.pricePerMinute.toFixed(
																			2
																	  )
																	: 0
															} €`}
														</span>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-lightblue">
													<span className="info-box-icon">
														<i className="fas fa-lock" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Start Price
														</span>
														<span className="info-box-number">
															{`${
																car?.pricing
																	?.startingFee
																	? car?.pricing?.startingFee.toFixed(
																			2
																	  )
																	: 0
															} €`}
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Visible in App
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${car.available}`}
															</span>
														</div>
													</div>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Current Location
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																<a
																	style={
																		location1
																	}
																	target="_blank"
																	rel="noopener noreferrer"
																	href={
																		this
																			.state
																			.locationLink
																	}
																>
																	Show On Map
																</a>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Current Status
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${car.status}`}
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																IoT Connection
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																{`${formatDate(
																	car
																		?.liveInfo
																		?.modifiedAt
																)}`}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-12 col-sm-3">
												<div className="info-box">
													<div className="info-box-content">
														{car.status !==
															'VERIFIED' && (
															<button
																type="button"
																className="btn bg-gradient-secondary"
																onClick={() =>
																	this.handleChangeCarStatus(
																		'VERIFIED'
																	)
																}
															>
																{`Show On App`}
															</button>
														)}
													</div>
													<span className="info-box-icon bg-warning elevation-1">
														<i className="fas fa-eye" />
													</span>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box">
													<div className="info-box-content">
														{car.type !== 'CAR' ? (
															<div>
																{car.available ===
																	'ONLINE' && (
																	<button
																		type="button"
																		className="btn bg-gradient-secondary"
																		onClick={() => {
																			this.handleTransportCar(
																				true
																			);
																		}}
																	>
																		{`Unlock For Storage`}
																	</button>
																)}
																{car.available ===
																	'OFFLINE' && (
																	<button
																		type="button"
																		className="btn bg-gradient-secondary"
																		onClick={() =>
																			this.handleTransportCar(
																				false
																			)
																		}
																	>
																		{`Deploy On Location`}
																	</button>
																)}
															</div>
														) : (
															<div>
																{car?.liveInfo
																	?.lockStatus ===
																	'LOCKED' && (
																	<button
																		type="button"
																		className="btn bg-gradient-secondary"
																		onClick={() => {
																			this.handleDoorLockCar(
																				true
																			);
																		}}
																	>
																		{
																			'Unlock Doors'
																		}
																	</button>
																)}
																{car?.liveInfo
																	?.lockStatus ===
																	'UNLOCKED' && (
																	<button
																		type="button"
																		className="btn bg-gradient-secondary"
																		onClick={() =>
																			this.handleDoorLockCar(
																				false
																			)
																		}
																	>
																		{
																			'Lock Doors'
																		}
																	</button>
																)}
															</div>
														)}
													</div>
													<span className="info-box-icon bg-warning elevation-1">
														<i className="fas fa-dolly" />
													</span>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												{car.type !== 'CAR' && (
													<div className="info-box">
														<div className="info-box-content">
															<div className="buttons">
																<button
																	type="button"
																	className="btn bg-gradient-secondary"
																	onClick={() =>
																		this.handleLookForCar()
																	}
																	disabled={
																		this
																			.state
																			.isLookForDisabled
																	}
																>
																	{`Trigger Ring`}
																</button>
															</div>
														</div>
														<span className="info-box-icon bg-warning elevation-1">
															<i className="fas fa-bell" />
														</span>
													</div>
												)}
												{car.type === 'CAR' && (
													<div className="info-box">
														<div className="info-box-content">
															<div className="buttons">
																{car?.liveInfo
																	?.imobilized !==
																	true && (
																	<button
																		type="button"
																		className="btn bg-gradient-secondary"
																		onClick={() => {
																			this.handleEngineKillCar(
																				true
																			);
																		}}
																	>
																		{
																			'Disable Engine'
																		}
																	</button>
																)}
																{car?.liveInfo
																	?.imobilized ===
																	true && (
																	<button
																		type="button"
																		className="btn bg-gradient-secondary"
																		onClick={() =>
																			this.handleEngineKillCar(
																				false
																			)
																		}
																	>
																		{
																			'Enable Engine'
																		}
																	</button>
																)}
															</div>
														</div>
														<span className="info-box-icon bg-warning elevation-1">
															<i className="fas fa-exclamation-circle" />
														</span>
													</div>
												)}
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box">
													<div className="info-box-content">
														{car?.liveInfo
															?.bleLockExists &&
															car?.liveInfo
																?.lockStatus ===
																'LOCKED' && (
																<button
																	type="button"
																	className="btn bg-gradient-secondary"
																	onClick={() =>
																		this.handleLockReleaseCar()
																	}
																	disabled={
																		this
																			.state
																			.isBTLockBtnDisabled
																	}
																>
																	{`Unlock Bluetooth Lock`}
																</button>
															)}
													</div>
													<span className="info-box-icon bg-warning elevation-1">
														<i className="fas fa-unlock-alt" />
													</span>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="card card-teal collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-info-circle fa-lg"
																style={{
																	marginRight:
																		'10px'
																}}
															/>
															<strong>{` ${car.type} INFO`}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<span className="info-box-text text-center text-muted">
																				Serial
																				Number
																			</span>
																			<span className="info-box-number text-center text-muted mb-0">
																				{`${car.serialNumber}`}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<span className="info-box-text text-center text-muted">
																				IoT
																				IMEI
																			</span>
																			<span className="info-box-number text-center text-muted mb-0">
																				{`${car?.trackerInfo?.trackerIMEI}`}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<span className="info-box-text text-center text-muted">
																				IoT
																				SIM
																			</span>
																			<span className="info-box-number text-center text-muted mb-0">
																				{`${car?.trackerInfo?.trackerPhone}`}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<span className="info-box-text text-center text-muted">
																				Bluetooth
																				Lock
																				IMEI
																			</span>
																			<span className="info-box-number text-center text-muted mb-0">
																				{`${
																					car
																						?.trackerInfo
																						?.btlockIMEI ||
																					'/'
																				}`}
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="tab-pane fade"
										id="custom-tabs-three-2"
										role="tabpanel"
										aria-labelledby="custom-tabs-three-2-tab"
									>
										<div className="row">
											<div className="col-md-8">
												<div className="card card-info collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-history fa-lg"
																style={{
																	marginRight:
																		'10px'
																}}
															/>
															<strong>{` Rent History `}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="col-12 col-sm-6">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<span className="info-box-text text-center text-muted">
																					{`Last Renter`}
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{`${
																						this
																							.state
																							.lastRent
																							?.renter
																							?.firstName ||
																						'/'
																					} ${
																						this
																							.state
																							.lastRent
																							?.renter
																							?.lastName ||
																						'/'
																					}`}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-6">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<span className="info-box-text text-center text-muted">
																					{`${
																						this
																							.state
																							.lastRent
																							?.renter
																							?.email ||
																						'/'
																					}`}
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{`${
																						this
																							.state
																							.lastRent
																							?.renter
																							?.phone ||
																						'/'
																					}`}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-12 col-sm-6">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<span className="info-box-text text-center text-muted">
																					Last
																					Rent
																					End
																					Time
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{`${formatDate(
																						car
																							?.lastState
																							?.lastRent ||
																							0
																					)}`}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<span className="info-box-text text-center text-muted">
																					Last
																					Photo
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{car
																						?.lastState
																						?.lastPhoto !==
																					'BALANCE_LOW' ? (
																						<a
																							style={
																								lastPhoto
																							}
																							target="_blank"
																							rel="noopener noreferrer"
																							href={
																								car
																									?.lastState
																									?.lastPhoto
																							}
																						>
																							Show
																							Photo
																						</a>
																					) : (
																						'Automatic End (balance low)'
																					)}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<span className="info-box-text text-center text-muted">
																					Last
																					Location
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					<a
																						style={
																							location2
																						}
																						target="_blank"
																						rel="noopener noreferrer"
																						href={
																							car
																								?.lastState
																								?.lastLocationLink
																						}
																					>
																						Show
																						On
																						Map
																					</a>
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-4">
												<div className="card card-danger collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-satellite-dish fa-lg"
																style={{
																	marginRight:
																		'10px'
																}}
															/>
															<strong>{` IoT Commands `}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="info-box">
																	<div className="info-box-content">
																		<button
																			type="button"
																			className="btn bg-gradient-secondary"
																			onClick={() =>
																				this.handleIoTRestartCar()
																			}
																			disabled={
																				this
																					.state
																					.isIoTRstBtnDisabled
																			}
																		>
																			{`Restart IoT`}
																		</button>
																	</div>
																	<span className="info-box-icon bg-warning elevation-1">
																		<i className="fas fa-power-off" />
																	</span>
																</div>
															</div>
															<div className="row">
																<div className="info-box">
																	<div className="info-box-content">
																		{car.type !==
																			'CAR' &&
																			car
																				?.liveInfo
																				?.lockStatus ===
																				'LOCKED' && (
																				<button
																					type="button"
																					className="btn bg-gradient-secondary"
																					onClick={() =>
																						this.handleLockRestartCar()
																					}
																					disabled={
																						this
																							.state
																							.isBTLockRstBtnDisabled
																					}
																				>
																					{`Restart BT-Lock`}
																				</button>
																			)}
																	</div>
																	<span className="info-box-icon bg-warning elevation-1">
																		<i className="fas fa-lock" />
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-8">
												<div className="card card-success collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-exchange-alt fa-lg"
																style={{
																	marginRight:
																		'10px'
																}}
															/>
															<strong>{` Vehicle Note `}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="col-12 col-sm-8">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<label htmlFor="inputName">
																					Vehicle
																					Note
																				</label>
																				<div className="input-group">
																					<input
																						type="text"
																						value={
																							this
																								.state
																								.noteInput
																						}
																						onChange={
																							this
																								.handleNoteChange
																						}
																					/>
																					<button
																						onClick={() =>
																							this.handleVehicleNoteSubmit()
																						}
																						className="btn btn-primary save-btn"
																						type="button"
																					>
																						Save
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										{car.type === 'CAR' && (
											<div>
												<div className="row">
													<div className="col-md-4">
														<div className="card card-indigo collapsed-card">
															<div
																className="card-header"
																data-card-widget="collapse"
															>
																<h3 className="card-title">
																	<i
																		className="fas fa-globe-europe fa-lg"
																		style={{
																			marginRight:
																				'10px'
																		}}
																	/>
																	<strong>{` ALLOWED COUNTRIES `}</strong>
																</h3>
																<div className="card-tools">
																	<button
																		type="button"
																		className="btn btn-tool"
																		data-card-widget="collapse"
																	>
																		<i className="fas fa-plus" />
																	</button>
																</div>
															</div>

															<div className="card-body">
																<div className="form-group">
																	<div className="row">
																		{car.type ===
																			'CAR' && (
																			<div>
																				<div className="form-group">
																					{checkboxes.map(
																						item => (
																							<div className="form-check">
																								<label
																									key={
																										item.value
																									}
																								>
																									<Checkbox
																										name={
																											item.value
																										}
																										checked={this.state.checkedItems.get(
																											item.value
																										)}
																										onChange={
																											this
																												.handleCheckboxChange
																										}
																									/>
																									{
																										item.name
																									}
																								</label>
																							</div>
																						)
																					)}
																				</div>
																			</div>
																		)}
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col-md-8">
														<div className="card card-navy  collapsed-card">
															<div
																className="card-header"
																data-card-widget="collapse"
															>
																<h3 className="card-title">
																	<i
																		className="fas fa-user-lock fa-lg"
																		style={{
																			marginRight:
																				'10px'
																		}}
																	/>
																	<strong>{` PRIVATE GROUP ( Phone Numbers ) `}</strong>
																</h3>
																<div className="card-tools">
																	<button
																		type="button"
																		className="btn btn-tool"
																		data-card-widget="collapse"
																	>
																		<i className="fas fa-plus" />
																	</button>
																</div>
															</div>

															<div className="card-body">
																<div className="form-group">
																	<div className="row">
																		<div className="col-md-6">
																			<label htmlFor="inputName">
																				Add
																				New
																				Number
																			</label>
																			<div className="input-group">
																				<div className="input-group-prepend">
																					<span className="input-group-text">
																						<i className="fas fa-phone" />
																					</span>
																				</div>

																				<input
																					type="text"
																					className="form-control"
																					value={
																						this
																							.state
																							.phoneInput ||
																						''
																					}
																					placeholder={
																						'386 41 321 321'
																					}
																					onChange={e =>
																						this.handleChangePhoneField(
																							e,
																							'phoneInput'
																						)
																					}
																				/>
																				<div className="input-group">
																					<button
																						onClick={() =>
																							this.handlePhoneSaveClick(
																								'ADD',
																								null
																							)
																						}
																						className="btn btn-primary save-btn"
																						type="button"
																					>
																						Add
																						to
																						List
																					</button>
																				</div>
																			</div>
																		</div>

																		<div className="col-md-6">
																			<label htmlFor="inputName">
																				Current
																				Numbers
																				List
																			</label>
																			<ListGroup>
																				<div className="direct-chat-messages">
																					{this.state.car?.private?.phoneList?.map(
																						el => (
																							<ListGroup.Item
																								key={
																									el
																								}
																							>
																								<div className="input-group">
																									<div className="input-group-prepend">
																										<button
																											className="btn btn-light"
																											style={{
																												margin: 0
																											}}
																											onClick={() =>
																												this.handlePhoneSaveClick(
																													'DELETE',
																													el
																												)
																											}
																										>
																											<i className="fas fa-trash-alt" />
																										</button>
																										<input
																											type="text"
																											className="form-control"
																											value={
																												el
																											}
																											readOnly
																										/>
																									</div>
																								</div>
																							</ListGroup.Item>
																						)
																					)}
																				</div>
																			</ListGroup>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
